<script>
import { authUserMethods } from  "../../../state/helpers/authuser"
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";

/**
 * Forgot password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      email: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      submitted: false,
      isAuthError: false,
      authError: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authUserMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      // stop here if form is invalid
      // eslint-disable-next-line no-console
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.forgetPassword({email:this.email}).then(() => {
          localStorage.setItem('reset_email', this.email)
          this.email = ""
        });
      }
    }
  }
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="">
              <div class="text-primary text-center p-4">
                <h5 class="text-success font-size-20 p-2">Safe and Sound Real Estate Inc</h5>
               
                <p class="text-dark">Reset Password</p>
                
              </div>
            </div>
            <div class="card-body p-4" style="margin-top:-60px !important;">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >
                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <b-form @submit.prevent="tryToReset" class="form-horizontal mt-4">
                  <b-form-group id="email-group" label="Email" label-for="email" class="mb-3" label-class="form-label">
                    <b-form-input id="email" v-model="email" :class="{ 'is-invalid': submitted && $v.email.$error }" type="email" placeholder="Enter email"></b-form-input>
                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">Email is required.</span>
                      <span v-if="!$v.email.email"
                        >Please enter valid email.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row mb-0">
                    <div class="col-12 text-end">
                      <b-button type="submit" variant="outline-orange" class="w-md">Reset Password</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
            
              <router-link to="/login" class="fw-medium text-orange">Login</router-link>
            </p>
            <p class="mb-0">
              ©{{ new Date().getFullYear() }} Safe and Sound Real Estate Inc
            
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
